.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    align-items: center;
    color: azure;
    padding: 2.5rem;
    gap: 2vh;
}

.contact-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    align-items: center;
    color: azure;
    gap: 4vh;
    width: 85%;
}

.links{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    grid-column-gap: 5vh;
    grid-row-gap: 1vh;
}

a{
    font-family: 'Tilt Warp', sans-serif;
    font-size: clamp(
        var(--font-size-6),
        3vw + 1rem,
        var(--font-size-3)
    );
    font-weight: 900;
    cursor: pointer;
}

ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(5, auto);
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    align-items: center;
}

ul li:hover {
    background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1020px), (max-height: 450px) {
    ul{
        grid-template-columns: repeat(2, auto);
    }
}

@media (max-width: 700px), (max-height: 450px) {
    .links{
        grid-template-columns: repeat(2, 1fr);
    }
    ul{
        grid-template-columns: repeat(1, auto);
    }
}

@media (max-width: 450px), (max-height: 300px) {
    .links{
        grid-template-columns: repeat(1, 1fr);
    }
}
