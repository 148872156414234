.welcome{
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: azure;
  text-align: center;
  justify-content: center;
}

.roles{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  gap: 5vh;
}

@media (max-width: 700px) {
  .rol-1 {
      order: 1;
    }
  
    .rol-2 {
      width: 100%;
      order: 0;
    }
    .rol-3 {
      order: 2;
    }
}

@media (max-height: 300px) {
  .roles{
      flex-direction: column;
      justify-content: center;
  }
}

.header1{
position: relative;
color: white;
mix-blend-mode: lighten;
display: inline-block;

&:before,
&:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    width: 100%;
    background: black;
    clip: rect(0, 0, 0, 0);
}

&:before {
    left: -1px;
    text-shadow: 1px 0 rgba(255,0,0,0.7);
}

&:after {
    left: 1px;
    text-shadow: -1px 0 rgba(0,0,255,0.7);
}

&:hover {
  &:before {
    text-shadow: 4px 0 rgba(255,0,0,0.7);
    animation: glitch-loop-1 0.8s infinite ease-in-out alternate-reverse;
  }
  &:after {
    text-shadow: -5px 0 rgba(0,0,255,0.7);
    animation: glitch-loop-2 0.8s infinite ease-in-out alternate-reverse;
  }
}
}

@keyframes glitch-loop-1 {
0% { clip: rect(36px, 9999px, 9px, 0) }
25% { clip: rect(25px, 9999px, 99px, 0) }
50% { clip: rect(50px, 9999px, 102px, 0) }
75% { clip: rect(30px, 9999px, 92px, 0) }
100% { clip: rect(91px, 9999px, 98px, 0) }
}

@keyframes glitch-loop-2 {
0% { top: -1px; left: 1px; clip: rect(65px, 9999px, 119px, 0) }
25% { top: -6px; left: 4px; clip: rect(79px, 9999px, 19px, 0) }
50% { top: -3px; left: 2px; clip: rect(68px, 9999px, 11px, 0) }
75% { top: 0px; left: -4px; clip: rect(95px, 9999px, 53px, 0) }
100% { top: -1px; left: -1px; clip: rect(31px, 9999px, 149px, 0) }
}

h4:empty:before {
  content: "\00a0";
}
