.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    text-align-last: center;
    align-items: center;
    color: azure;
    padding: 2.5rem;
}

.about-external-container {
    padding: 2.5rem;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    column-gap: 2vh;
}