:root {
    --background-dark-color: #181818;
    --background-light-color: #eaeaea;
    --border-color: #89ff98;
    --text-color-dark: #000000;
    --text-color-light: azure;
    --font-size-1: 16rem; /*72px*/
    --font-size-1-1: 8rem; /*72px*/
    --font-size-2: 3.5rem; /*56px*/
    --font-size-3: 3rem; /*48px*/
    --font-size-4: 2.25rem; /*36px*/
    --font-size-5: 2rem; /*32px*/
    --font-size-6: 1.5rem; /*24px*/
    --font-size-7: 1.125rem; /*18px*/
    --font-size-8: 1rem; /*16px*/
    --font-size-9: 0.875rem; /*14px*/
    --cursor-normal: default;
}

.header1, .header2, .header3, .header4, .header5, .paragraph1{
    cursor: var(--cursor-normal);
}
  
.header1 {
    font-family: 'Tilt Warp', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-3),
        10vw + 1.5rem, /*valor en responsive*/
        var(--font-size-1)
    );
    font-weight: 900;
    -webkit-text-stroke: thin;
}

.header2 {
    font-family: 'Tilt Warp', sans-serif;
    font-size: clamp(
        var(--font-size-6),
        3vw + 1rem,
        var(--font-size-3)
    );
    font-weight: 900;

}
  
.header3 {
    font-family: 'Tilt Warp', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-7),
        2vw + 0.75rem,
        var(--font-size-4)
    );
    font-weight: 900;

}

.header-link {
    font-family: 'Tilt Warp', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-7),
        2vw + 0.75rem,
        var(--font-size-4)
    );
    cursor: pointer;
    font-weight: 900;

}

.header4 {
    font-family: 'Tilt Warp', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-6),
        1.5vw + 0.5rem,
        var(--font-size-1-1)
    );
    font-weight: 900;
}

.header5 {
    font-family: 'Tilt Warp', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-9),
        1vw + 0.325rem,
        var(--font-size-6)
    );
}

.paragraph1 {
    font-family: 'Oswald', sans-serif;
    margin: 0;
    font-size: clamp(
        var(--font-size-8),
        1vw + 0.5rem,
        var(--font-size-4)
    );
    font-weight: 100;
    line-height: 1.8;
    background-color: #18181875;
}