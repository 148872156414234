.skills{
    display: flex;
    color: azure;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem;
}

.skills-external-container, .skills-external-container-1{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    
}

.skills-divided{
    color: azure;
    align-items: center;
    flex-wrap: wrap;
    gap: 4vh;
    justify-content: center;
    width: 100%;
    display: flex;
    justify-items: center;
    align-content: center;
    column-gap: 6vh;
}

.rol {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uxui, .fullstack {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    column-gap: 2vh;
}

@media (max-width: 800px) and (min-width: 460px), (max-height: 300px) {
    .skills-external-container-1{
        width: 100%;
    }
}

@media (max-width: 460px), (max-height: 300px) {
    .skills-external-container-1{
        width: 100%;
    }
}