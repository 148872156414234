.navbar {
    display: flex;
    justify-content: space-between;
    color: azure;
    position: sticky;
    top: 0;
    padding: 1rem;
    z-index: 1;
  }

  .navbar.scrolled{
      background-color: #18181875;
      backdrop-filter: blur(10px);
  }
  
  .menu-button {
    display: none;
  }
  
  .nav-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .logo{
    display: flex;
    width: clamp(1.125rem, 2vw + 0.75rem, 2.25rem);
    height: auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  @media (max-width: 560px), (max-height: 300px) {
    .navbar {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      gap: 4vh;
    }
  
    .menu-button {
      display: block;
      font-size: 1.5rem;
      background: none;
      border: none;
      color: azure;
      cursor: pointer;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      gap: 4vh;
    }
  
    .nav-links.open {
      display: flex;
      align-items: center;
    }
  }
  