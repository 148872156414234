.projects{
    display: flex;
    color: azure;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem;
    text-align: center;
}

.project-external-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10vh;
    grid-column-gap: 5vh;
    justify-items: center;
    width: auto;
}

.project-internal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1vh;
}

.content {
    aspect-ratio: 1 / 1;
    overflow: hidden;
    position: relative;
    border: 1vw solid azure;
    width: 75%
  }


.content img{
    display: block;
    object-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: azure;
    display: block;
    height: 100%;
  }

  a:hover {
    color: azure;
  }

  a:active {
    color: azure;
    text-decoration: none; 
  }

p{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    text-align-last: center;
    align-items: center;
    color: azure;
    padding: 1rem;
  }

.description{
    display: flex;
    height: 100%;
    object-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.modal-overlay {
  p{
    background-color: transparent;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  padding: 5vh;
  margin: 10vh;
  text-align: center;
  z-index: 4;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #18181875;
  a{
    cursor: pointer;
  }
}

@media (max-width: 560px), (max-height: 300px) {
    .project-external-container{
        grid-template-columns: repeat(1, 1fr);
    }

    .modal-content {
      width: 75vw;
    }
}